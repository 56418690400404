@font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 300;
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"),
        url("./IBMPlexSans-LightItalic.ttf") format("truetype"),
}

@font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 300;
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"),
        url("./IBMPlexSans-Light.ttf") format("truetype"),
}

@font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    src: local("IBM Plex Sans Regular"), local("IBMPlexSans-Regular"),
        url("./IBMPlexSans-Regular.ttf") format("truetype"),
}

@font-face {
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 400;
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"),
        url("./IBMPlexSans-Italic.ttf") format("truetype"),
}

@font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 700;
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"),
        url("./IBMPlexSans-Bold.ttf") format("truetype"),
}